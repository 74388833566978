import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "./../img/logo.png";

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const menu = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Services",
      path: "/services",
    },
    {
      name: "Portfolio",
      path: "/portfolio",
    },
    {
      name: "Contact",
      path: "/contact",
    },
  ];

  function scrollToTop() {
    setIsOpen(false);
    window.scrollTo(0, 0);
  }

  return (
    <nav className="px-[5vw] pt-[1.5vh] md:py-none bg-gradient-to-b from-bgcolor to-white fixed w-full top-0 z-40">
      <div className="hidden md:block">
        <div className="mx-[5vw] text-[14px] text-secondary flex justify-between ">
          <p>117, 2nd floor Exceluss Business Space, Surat</p>
          <p>hr@codminers.com</p>
          <p>+91 9376979291</p>
          <div className="flex gap-2 md:gap-4">
            <Link
              to="https://www.linkedin.com/company/codminers/"
              target="_blank"
              className="border border-white hover:text-primary transition-all duration-300"
            >
              <i className="fa-brands fa-linkedin-in"></i>
            </Link>
            <Link
              to="https://www.linkedin.com/company/codminers/"
              target="_blank"
              className="border border-white hover:text-primary transition-all duration-300"
            >
              <i className="fa-brands fa-facebook-f"></i>
            </Link>
            <Link
              to="https://instagram.com/codminersinfotech?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              className="border border-white hover:text-primary transition-all duration-300"
            >
              <i className="fa-brands fa-instagram"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="py-2 flex justify-between">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Link>
            <img
              src={logo}
              alt="Logo"
              className="w-[45px] h-[45px] md:w-[55px] md:h-[55px]"
            />
          </Link>
        </motion.div>
        <div className="hidden md:block">
          <motion.div
            initial={{ x: 100, opacity: 0, scale: 0.5 }}
            whileInView={{ x: 0, opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            className="flex gap-8 items-center"
          >
            {menu.map((item, i) => (
              <Link
                key={i}
                onClick={scrollToTop}
                to={`${item.path}`}
                className="w-100% text-primary hover:text-secondary active:text-secondary py-[10px] tracking-wider border-b-[3px] border-b-white hover:border-b-secondary text-md font-bold transition-all duration-500 ease-in-out"
              >
                {item.name}
              </Link>
            ))}
          </motion.div>
        </div>
        <div className="md:hidden">
          <button
            onClick={toggleNavbar}
            className="text-primary hover:text-secondary focus:outline-none focus:text-secondary"
          >
            <i
              className={`fas ${
                isOpen
                  ? "fa-solid fa-xmark fa-spin"
                  : "fa-solid fa-bars fa-spin"
              } text-primary text-[1.5rem] hover:text-secondary focus:outline-none focus:text-secondary`}
              onClick={toggleNavbar}
            />
          </button>
        </div>
      </div>
      {isOpen && (
        <div className=" flex flex-col items-center bg-bgcolor transition ease-in duration-300">
          {menu.map((item, i) => (
            <Link
              onClick={scrollToTop}
              key={i}
              to={`${item.path}`}
              className="text-primary hover:text-secondary active:text-secondary py-[10px] tracking-wider border-b-[3px] border-b-bgcolor hover:border-b-secondary text-md font-bold transition-all duration-500 ease-in-out"
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}
    </nav>
  );
}

export default Navigation;
