import React from "react";
import { motion } from "framer-motion";
import show from "./../img/blueoffice.png";

function Show() {
  return (
    <section className="">
      <div className="w-full xsm:min-h-[50vh] lg:h-[90vh] px-[5vw] xsm:py-[3vh] lg:py-[10vh] flex flex-col-reverse gap-4 md:flex-row justify-between overflow-x-hidden items-center bg-gradient-to-t from-white to-bgcolor box-border">
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
          className="xsm:w-full w-2/4 flex justify-center items-center"
        >
          <img
            src={show}
            alt="Office"
            className="xsm:w-3/4 xsm:h-3/4 md:w-full md:h-[70vh]"
          />
        </motion.div>
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
          className="xsm:w-full w-2/4  px-[2vw] text-center text-primary xsm:text-[1.5rem]  md:text-[2.3rem] lg:text-[3rem] font-bold"
        >
          <h2 className="">
            Our Endless
            <br /> Creativity and Innovation
            <br />
            for Your Business
          </h2>
        </motion.div>
      </div>
    </section>
  );
}

export default Show;
