import React from "react";
import contact from "./../img/contact.jpg";
import mailus from "./../img/mailus.jpg";

function Contact() {
  return (
    <>
      <img src={contact} alt="Contact" className="mt-[10vh] mt:[15vh]" />
      <section className="">
        <h2 className="my-[5vh] md:my-[8vh] text-[1.5rem] md:text-[2rem] lg:text-[3rem] text-primary text-center font-bold">
          We're happy to meet you
        </h2>
        <div className="flex justify-center items-center">
          <div className="flex flex-col md:flex-row">
            <div className="px-[5vw] text-center flex items-center gap-6 sm:gap-14">
              <i className="fa-solid fa-location-dot text-[2.5rem] md:text-[4rem] text-primary"></i>
              <div className="leading-8 md:leading-10">
                <p className="text-[#4d4d4d] md:text-[1.3rem]">
                  117, 2nd floor Exceluss Business Space
                </p>
                <p className="text-[#4d4d4d]  md:text-[1.3rem]">
                  Althan, Surat, Gujarat 395017
                </p>
              </div>
            </div>
            <div className="px-[5vw] py-[5vh] text-center flex flex-col justify-evenly items-center gap-6">
              <div className="w-full text-[1rem] flex flex-start items-center gap-6 sm:gap-14">
                <div className="">
                  <i className="fa-solid fa-mobile-screen text-[2rem] md:text-[3rem] text-primary"></i>
                </div>
                <div className="text-[#4d4d4d] md:text-[1.3rem]">
                  +91 9376979291
                </div>
              </div>
              <div className="w-full text-[1rem] flex flex-start items-center gap-6 sm:gap-14">
                <div>
                  <i className="fa-solid fa-envelope text-[2rem] md:text-[3rem] text-primary"></i>
                </div>
                <p className="text-[#4d4d4d] md:text-[1.3rem]">
                  hr@codminers.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h3 className="my-[5vh] md:my-[8vh] text-[1.5rem] md:text-[2rem] lg:text-[3rem] text-primary text-center font-bold">
          Mail us
        </h3>
        <div className="sm:mx-[5vw] sm:px-[3vw] flex justify-center items-center gap-4">
          <form
            action="https://formspree.io/f/mjvqglka"
            method="POST"
            className="w-[260px] xsm:w-[90%] md:w-2/4 flex flex-col gap-8"
          >
            <input
              type="text"
              name="name"
              placeholder="Name"
              autoComplete="off"
              required
              className="py-[.8vh] md:py-[1.2vh] px-[2vw]  text-[#4d4d4d] text-[1rem]  border-2 border-primary rounded-xl focus:outline-none"
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              autoComplete="off"
              required
              className="py-[.8vh] px-[2vw]  text-[#4d4d4d] text-[1rem] md:text-[1.2rem] border-2 border-primary rounded-xl focus:outline-none"
            />
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              autoComplete="off"
              required
              className="py-[.8vh] px-[2vw]  text-[#4d4d4d] text-[1rem] md:text-[1.2rem] border-2 border-primary rounded-xl focus:outline-none"
            />
            <textarea
              name="message"
              id=""
              cols="30"
              rows="6"
              placeholder="Message..."
              className="py-[.8vh] px-[2vw]  text-[#4d4d4d] text-[1rem] md:text-[1.2rem] border-2 border-primary rounded-xl focus:outline-none"
            ></textarea>
            <div className="flex justify-center">
              <button
                type="submit"
                className="w-[150px] py-[1.2vh] text-primary text-[1.2rem] font-semibold border-2 border-primary hover:bg-secondary hover:text-white hover:border-secondary rounded-full"
              >
                Submit
              </button>
            </div>
          </form>
          <div className="hidden md:block w-2/4">
            <img
              src={mailus}
              alt="Mail-us"
              className="h-[550px] object-contain"
            />
          </div>
        </div>
      </section>
      <iframe
        title="office"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.433338785221!2d72.79761787413884!3d21.135146284118225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be05322f2279c11%3A0x10af873d7f5357a3!2sCodMiners%20Infotech%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1691058048628!5m2!1sen!2sin"
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="my-[10vh] w-full h-[300px] md:h-[400px]"
      />
    </>
  );
}

export default Contact;
