import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./../css/projectCard.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// import flowite from "flowbite";

function ProjectCard({ id, title, images, description, technologies }) {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="w-[300px] rounded-lg shadow-md shadow-shadow">
      <img
        key={id}
        src={images[0]}
        alt={title}
        onClick={openModal}
        className={`transform transition-transform rounded-t-lg`}
      />
      {modalOpen && (
        <div className="backdrop-blur-sm bg-black bg-opacity-50 fixed inset-0 flex items-center justify-center z-50">
          <div className="w-full sm:w-3/4 md:w-2/4 bg-white text-right rounded-lg border-[1px] border-primary">
            <button
              className="px-4 py-2 text-red-500 text-[1.2rem] hover:text-red-700"
              onClick={closeModal}
            >
              <i className="fa-solid fa-xmark fa-spin"></i>
            </button>
            <Swiper
              spaceBetween={30}
              // centeredSlides={true}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              // }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              loop={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {images?.map((image, idx) => (
                <SwiperSlide key={idx} className="rounded-b-lg">
                  <img src={image} alt="Project" className="rounded-b-lg" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}

      <div className="px-[5vw] md:px-[1vw] py-[10px]">
        <h3 className="pt-[5px] md:pt-[10px] text-primary font-bold">
          {title}
        </h3>
        <p className="py-[10px] text-textColor">{description}</p>
        <div className="flex flex-wrap">
          {technologies?.map((tech, idx) => (
            <span
              key={idx}
              className="py-[2px] px-[5px] mr-[6px] mb-[5px] bg-shadow text-primary text-[13px] rounded"
            >
              {tech}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
