import mission from "./../img/mission.jpg";
import who from "./../img/who.png";
import experties from "./../img/experties.jpg";
import choose from "./../img/choose.webp";
import values from "./../img/values.jpg";

const aboutContent = [
  {
    img: mission,
    title: "Our Mission",

    content:
      "CodMiners Infotech Pvt. Ltd. empowers businesses with growth-driven software solutions. We aim to be a reliable technology partner, enhancing productivity and user experiences to thrive in the digital age.",
  },
  {
    img: who,
    title: "Who Are We?",
    content:
      "We are a team of passionate and skilled professionals, committed to excellence in software development. With years of experience and expertise, we have successfully delivered top-notch solutions across various industries.",
  },
  {
    img: experties,
    title: "Our Expertise",
    content:
      "CodMiners Infotech Pvt. Ltd. specializes in custom software solutions. We create web apps, mobile apps, cloud-based systems, and enterprise software, transforming ideas into reality for our clients.",
  },
  {
    img: choose,
    title: "Why Choose Us?",
    content:
      "We provide innovative, client-centric solutions using cutting-edge technologies. With dedicated support and rigorous quality assurance, we deliver high-performance products that align perfectly with your business goals.",
  },
  {
    img: values,
    title: "Our Values",
    content:
      "Integrity, transparency, and commitment are the core values that guide us in every project we undertake. We value long-term partnerships and are driven by a passion for excellence.",
  },
];

export default aboutContent;
