// src/About.js
import React from "react";
import aboutContent from "../js/about";
import about from "./../img/about.jpg";

const About = () => {
  return (
    <>
      <section className="my-16 mx-[5vw]">
        <div className="w-full">
          <img src={about} alt="About" className="w-full object-contain" />
        </div>
        {aboutContent.map((item, idx) => (
          <div
            key={idx}
            className="my-[5vh] sm:my-[10vh] md:my-[20vh] flex flex-col sm:flex-row sm:even:flex-row-reverse gap-8 justify-center"
          >
            <div className="sm:w-2/4 md:px-[8vw] flex flex-col justify-center items-center">
              <h2 className="mb-[5vh] text-center text-primary text-[1.2rem] md:text-[2rem] font-bold">
                {item.title}
              </h2>
              <p className="text-center text-textColor leading-8">
                {item.content}
              </p>
            </div>
            <div className="sm:w-2/4 flex justify-center items-center">
              <img
                src={item.img}
                alt={item.title}
                className="w-3/4 rounded-lg"
              />
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default About;

// src/img/values.jpg
