import React from "react";
import Hero from "../components/Hero";
import TechStack from "../components/TechStack";
import Team from "../components/Team";
import Show from "../components/Show";

function Home() {
  return (
    <>
      <Hero />
      <Show />
      <Team />
      <TechStack />
    </>
  );
}

export default Home;
