import React from "react";
import services from "./../js/services";
import TechStack from "../components/TechStack";
import service from "./../img/services.jpg";

const Services = () => {
  return (
    <section className="mt-16">
      <div className="w-full">
        <img src={service} alt="Services" className="w-full object-contain" />
      </div>
      <div className="px-[5vw] flex justify-center items-center">
        <div className="min-w-[200px] max-w-[600px] pt-[6vh] md:pt-[10vh] text-center">
          <h2 className="sm:text-[.8rem] md:text-[1rem] lg:text-[1.3rem] font-semibold text-secondary">
            Our Services
          </h2>
          <p className="sm:text-[1.2rem] md:text-[1.5rem] lg:text-[2.5rem] text-primary text-center font-bold">
            Custom IT Solutions <br />
            for Endless <br /> Growth of your Business
          </p>
        </div>
      </div>
      <p className="px-[10vw] py-[6vh] md:py-[10vh] text-[#4d4d4d] md:text-[1.3rem] text-center tracking-normal indent-8">
        At CodMiners Infotech Pvt.Ltd., we are passionate about transforming
        ideas into reality. Our services are designed to meet the evolving
        demands of the digital landscape while ensuring a seamless user
        experience and unparalleled results. Partner with us, and let's embark
        on a journey of innovation and growth together.
      </p>
      <div className="sm:px-[5vw] flex flex-wrap justify-evenly gap-14">
        {services.map((service, idx) => (
          <div
            key={idx}
            className="min:w-[250px] max-w-[300px] p-4 text-center bg-bgcolor rounded-lg hover:translate-y-[-2rem] duration-500 cursor-default"
          >
            <p className="pb-[10px] font-bold text-[3.5rem] text-primary">
              {service.icon}
            </p>
            <h3 className="pb-[10px] font-semibold text-primary">
              {service.name}
            </h3>
            <p className="text-[#4d4d4d]">{service.description}</p>
          </div>
        ))}
      </div>
      <TechStack />
    </section>
  );
};

export default Services;
