import React from "react";
import { motion } from "framer-motion";
import logos from "./../js/techstack";
import "./../css/techstack.css";

function TechStack() {
  return (
    <section className="px-[2vw] py-[5vh]">
      <motion.h2
        initial={{ scale: 0, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="md:py-[5vh] text-[1.2rem] xsm:text-[1.8rem]  md:text-[2.5rem] text-primary font-bold text-center"
      >
        We Work on Latest Technologies
      </motion.h2>

      <div className="logos">
        <div className="logos-slide">
          {logos.map((image, index) => (
            <div key={index} className="slide">
              <img src={image} alt={image} className="" />
            </div>
          ))}
          {logos.map((image, i) => (
            <div key={i} className="slide">
              <img src={image} alt={image} className="" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default TechStack;
