const services = [
  {
    name: "Web Application Development",
    icon: <i className="fa-solid fa-globe"></i>,
    description:
      "Engage users with dynamic web apps. Drive growth through interactive and powerful web solutions.",
  },
  {
    name: "Mobile App Development",
    icon: <i className="fa-solid fa-mobile-screen"></i>,
    description:
      "Mobile-first app development for iOS, Android and cross-platform. Engaging, user-friendly and brand-enhancing solutions. Stay ahead.",
  },
  {
    name: "UI/UX Design",
    icon: <i className="fa-solid fa-marker"></i>,
    description:
      "Our UI/UX design services focus on creating intuitive and visually appealing interfaces that leave a lasting impression on users.",
  },
  {
    name: "Cloud and DevOps Solutions",
    icon: <i className="fa-brands fa-aws"></i>,
    description:
      "Optimize with cloud & DevOps. Migrate, manage, streamline development processes for efficient application performance.",
  },
  {
    name: "Custom Software Development",
    icon: <i className="fa-brands fa-uncharted"></i>,
    description:
      "Our custom software development services cater to businesses of all sizes, tailoring solutions to fit your specific requirements.",
  },
  {
    name: "Software Testing and Quality Assurance",
    icon: <i className="fa-solid fa-vial"></i>,
    description:
      "Flawless performance assured. Quality at our core. Software testing and assurance services you can trust.",
  },
  {
    name: "Software Maintenance and Support",
    icon: <i className="fa-solid fa-plug-circle-minus"></i>,
    description:
      "We don't just stop at development; our services extend to long-term software maintenance and support.",
  },

  {
    name: "Enterprise Solutions",
    icon: <i className="fa-solid fa-building-circle-check"></i>,
    description:
      "Customized solutions for enterprises. Automate, integrate, boost productivity - unleashing your business's full potential.",
  },
];

export default services;
