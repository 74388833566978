import paras from "./../img/paras.jpeg";
import vanshree from "./../img/vanshree.jpeg";
import vedant from "./../img/vedant.jpeg";
import shivam from "./../img/shivam.jpeg";

const team = [
  {
    name: "Paras Bansal",
    profile: "CEO & Managing Director",
    description:
      "As CEO and MD, He leads our software company with vision and innovation, driving growth, fostering collaboration and delivering exceptional solutions to clients.",
    img: paras,
  },
  {
    name: "Vanshree Navik",
    profile: "Senior Software Engineer",
    description:
      "With 5+ years of experience, she is a seasoned software engineer, proficient in diverse technologies, problem-solving and delivering high-quality solutions for complex projects.",
    img: vanshree,
  },
  {
    name: "Vedant Patil",
    profile: "Software Engineer",
    description:
      "As a 2+ years experienced software engineer, he possesses a strong skill set in coding, testing and collaborating with teams to deliver efficient software solutions.",
    img: vedant,
  },
  {
    name: "Shivam Dubey",
    profile: "Full Stack Developer",
    description:
      "As a 1+ year experienced full stack developer, he demonstrates proficiency in full stack development, contributing to feature-rich web applications with dedication & adaptability.",
    img: shivam,
  },
];

export default team;
