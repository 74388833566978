import React from "react";
import hero from "./../img/office.png";
import { motion } from "framer-motion";

function Hero() {
  return (
    <section className="mt-16">
      <div className="w-full xsm:min-h-[50vh] lg:h-[90vh] px-[5vw] xsm:py-[3vh] lg:py-[10vh] flex flex-col gap-4 md:flex-row justify-between overflow-x-hidden items-center bg-gradient-to-b from-white to-bgcolor box-border">
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
          className="xsm:w-full w-2/4  px-[2vw] text-center text-primary xsm:text-[2rem]  md:text-[3rem] lg:text-[4rem] font-bold"
        >
          <h1>
            Take your
            <br />
            business online
            <br />
            with us
          </h1>
        </motion.div>
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
          className="xsm:w-full w-2/4 flex justify-center items-center"
        >
          <img
            src={hero}
            alt="Office"
            className="xsm:w-3/4 xsm:h-3/4 md:w-full md:h-[70vh]"
          />
        </motion.div>
      </div>
    </section>
  );
}

export default Hero;
