import ranktivity from "./../img/ranktivity.png";
import navigator from "./../img/navigator.png";
import codminers from "./../img/codminers.png";
import codminers2 from "./../img/codminers2.png";
// import easywork from "./../img/easywork.png";

const projects = [
  {
    id: 1,
    title: "Easy-Work",
    description:
      "A placement management web application to help colleges to make their placement process easier.",
    images: [codminers, codminers2],
    technologies: ["Next.js", "tailwindcss", "Python", "Flask", "Firebase"],
  },
  {
    id: 2,
    title: "Ranktivity",
    description:
      "Ranktivity offers comprehensive SEO tools for optimizing websites and improving online visibility.",
    images: [ranktivity, codminers2],
    technologies: [
      "Next.js",
      "tailwindcss",
      "Node.js",
      "firebase",
      "Google Cloud",
      "Puppeteer",
    ],
  },
  {
    id: 3,
    title: "Digital Navigator",
    description:
      "It optimizes web and social media for better visitor engagement and increased sales potential.",
    images: [navigator, codminers2],
    technologies: [
      "Next.js",
      "tailwindcss",
      "Python",
      "Flask",
      "Docker",
      "Google Cloud",
      "firestore",
    ],
  },
  {
    id: 4,
    title: "CodMiners Infotech Pvt. Ltd.",
    description:
      "This the company website to showcase its work to the visiters and give information about our company.",
    images: [codminers, codminers2],
    technologies: ["React", "tailwindcss", "Node.js", "Express.js", "MongoDB"],
  },
];

export default projects;
