import html from "./../img/html.png";
import css from "./../img/css.png";
import bootstrap from "./../img/bootstrap.png";
import sass from "./../img/sass.png";
import tailwind from "./../img/tailwind.png";
import javascript from "./../img/javascript.png";
import typescript from "./../img/typescript.png";
import react from "./../img/react.png";
import next from "./../img/next.png";
import aws from "./../img/aws.png";
import python from "./../img/python.png";
import flask from "./../img/flask.png";
import node from "./../img/node.png";
import express from "./../img/express.png";
import php from "./../img/php.png";
import mongo from "./../img/mongo.png";
import firebase from "./../img/firebase.png";
import mysql from "./../img/mysql.png";

const logos = [
  html,
  css,
  bootstrap,
  sass,
  tailwind,
  javascript,
  typescript,
  react,
  next,
  aws,
  python,
  flask,
  node,
  express,
  php,
  mongo,
  firebase,
  mysql,
];

export default logos;
