import React from "react";
import ProjectCard from "./../components/ProjectCard";
import projects from "./../js/projects";

function Portfolio() {
  console.log("hello");
  return (
    <section className="mx-[5vw] md:mx-[10vw] my-[10vh] md:my-[25vh]">
      <h2 className="mb-[10vh] text-[3rem] text-primary text-center font-bold">
        Our Projects
      </h2>
      <div className="flex flex-wrap justify-evenly md:justify-between gap-[5vw]">
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            id={project.id}
            images={project.images}
            title={project.title}
            description={project.description}
            technologies={project.technologies}
          />
        ))}
      </div>
    </section>
  );
}

export default Portfolio;
