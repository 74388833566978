import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import team from "./../js/team";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./../css/team.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function App() {
  const swiperRef = useRef(null);
  const [autoplayPaused, setAutoplayPaused] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(
    window.matchMedia("(max-width: 780px)").matches ? 1 : 2
  );

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(window.matchMedia("(max-width: 780px)").matches ? 1 : 2);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const swiperInstance = swiperRef.current?.swiper;

    if (swiperInstance) {
      swiperInstance.autoplay.pause();

      if (!autoplayPaused) {
        swiperInstance.autoplay.start();
      }
    }
  }, [autoplayPaused]);

  const pauseAutoplay = () => {
    if (!autoplayPaused) {
      setAutoplayPaused(true);
    }
  };

  const resumeAutoplay = () => {
    if (autoplayPaused) {
      setAutoplayPaused(false);
    }
  };

  return (
    <section className="relative z-10">
      <div className="md:mb-[10vh] text-primary text-center">
        <motion.h1
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1.5 }}
          className="xsm:text-[2rem] md:text-[3rem] lg:text-[4rem] font-bold"
        >
          Our Team
        </motion.h1>
      </div>
      <Swiper
        ref={swiperRef}
        loop={true}
        spaceBetween={30}
        slidesPerView={slidesPerView}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {team.map((member, idx) => (
          <SwiperSlide
            key={idx}
            onMouseEnter={pauseAutoplay}
            onMouseLeave={resumeAutoplay}
            onTouchStart={pauseAutoplay}
            onTouchEnd={resumeAutoplay}
            className="swiper-slide mx-0"
          >
            <div className="swiper-client-msg">
              <p>{member.description}</p>
            </div>
            <div className="swiper-client-data  flex flex-col sm:flex-row justify-evenly">
              <figure>
                <img loading="lazy" src={member.img} alt={member.name} />
              </figure>
              <div className="client-data-details">
                <p>{member.name}</p>
                <p className="text-textColor">{member.profile}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
